import React, { ComponentType } from 'react'
import style from './style/TPAUnavailableMessageOverlay.scss'
import {
	NAMESPACE,
	TPA_LOADING_ERROR_HEADER_DEFAULT,
	TPA_LOADING_ERROR_HEADER_KEY,
	TPA_LOADING_ERROR_RELOAD_DEFAULT,
	TPA_LOADING_ERROR_RELOAD_KEY,
	TPA_LOADING_ERROR_TEXT1_DEFAULT,
	TPA_LOADING_ERROR_TEXT1_KEY,
	TPA_LOADING_ERROR_TEXT2_DEFAULT,
	TPA_LOADING_ERROR_TEXT2_KEY,
} from './translations'

type TPAUnavailableMessageOverlayProps = {
	reload?: Function
	translate?: (feature: string, key: string, defaultValue: string) => string
	width: number
}

export const TPAUnavailableMessageOverlay: ComponentType<TPAUnavailableMessageOverlayProps> = ({
	reload,
	translate,
	width,
}) => {
	const title = translate
		? translate(NAMESPACE, TPA_LOADING_ERROR_HEADER_KEY, TPA_LOADING_ERROR_HEADER_DEFAULT)
		: TPA_LOADING_ERROR_HEADER_DEFAULT

	const text1 = translate
		? translate(NAMESPACE, TPA_LOADING_ERROR_TEXT1_KEY, TPA_LOADING_ERROR_TEXT1_DEFAULT)
		: TPA_LOADING_ERROR_TEXT1_DEFAULT

	const text2 = translate
		? translate(NAMESPACE, TPA_LOADING_ERROR_TEXT2_KEY, TPA_LOADING_ERROR_TEXT2_DEFAULT)
		: TPA_LOADING_ERROR_TEXT2_DEFAULT
	const reloadText = translate
		? translate(NAMESPACE, TPA_LOADING_ERROR_RELOAD_KEY, TPA_LOADING_ERROR_RELOAD_DEFAULT)
		: TPA_LOADING_ERROR_RELOAD_DEFAULT

	const MIN_WIDTH_TEXT_DISPLAY = 224
	const CONTENT_PADDING = 17
	const MAX_TEXT_WIDTH = 206
	const SVG_WIDTH = 22
	const contentWidth = width < MIN_WIDTH_TEXT_DISPLAY ? SVG_WIDTH : MAX_TEXT_WIDTH
	const paddingLeft = (width - contentWidth) / 2 - CONTENT_PADDING
	const textDisplay = width >= MIN_WIDTH_TEXT_DISPLAY

	return (
		<div className={style.content} style={{ paddingLeft }}>
			<div className={style.iconContainer}>
				<svg width="22px" height="23px" viewBox="0 0 22 23">
					<title>!</title>
					<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g
							id="Error-Message"
							transform="translate(-308.000000, -690.000000)"
							fill="#174165"
							fillRule="nonzero"
						>
							<g id="Group-3" transform="translate(66.000000, 75.000000)">
								<g id="Group-Copy" transform="translate(110.000000, 429.000000)">
									<g id="Group-2" transform="translate(128.000000, 186.000000)">
										<path
											d="M13.5652174,6 L16.4347826,6 L16.4347826,10.0908203 L15.9602582,13 L14.1082817,13 L13.5652174,10.0638428 L13.5652174,6 Z M13.5652174,14 L16.4347826,14 L16.4347826,17 L13.5652174,17 L13.5652174,14 Z M14.5217391,2 C9.76728781,2 5.91304348,6.02943725 5.91304348,11 L5.91304348,12 C5.91304348,16.9705627 9.76728781,21 14.5217391,21 L15.4782609,21 C20.2327122,21 24.0869565,16.9705627 24.0869565,12 L24.0869565,11 C24.0869565,6.02943725 20.2327122,2 15.4782609,2 L14.5217391,2 Z M14.5217391,0 L15.4782609,0 C21.2892569,0 26,4.92486775 26,11 L26,12 C26,18.0751322 21.2892569,23 15.4782609,23 L14.5217391,23 C8.71074307,23 4,18.0751322 4,12 L4,11 C4,4.92486775 8.71074307,0 14.5217391,0 Z"
											id="!"
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
			{textDisplay ? (
				<div className={style.textContainer}>
					<div className={style.textTitle}>{title}</div>
					<div className={style.text}>{text1}</div>
					<div className={style.text}>{text2}</div>
					{reload ? (
						/* eslint-disable-next-line jsx-a11y/anchor-is-valid */
						<a className={style.reloadButton} onClick={() => reload()}>
							{reloadText}
						</a>
					) : null}
				</div>
			) : null}
		</div>
	)
}

export default TPAUnavailableMessageOverlay
